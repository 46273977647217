<template>
  <div class="classroomAppointmentRecord-list">
    <div class="header">
      <div class="filter">
        <div class="filter-item"
             @click="vanPopupClick('status')">
          <span>{{ filter.status.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="showCalendarBtn('range')">
          <span>{{ filter.time.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div>
      </div>

      <!-- 选择弹框 -->
      <van-popup v-model="showVanPopupType"
                 round
                 position="bottom">
        <custom-popup :title="filter.title"
                      :columns="vanPopupTypeColumns"
                      value-key="name"
                      @cancel="vanPopupCancel"
                      @confirm="vanPopupConfirm"></custom-popup>
      </van-popup>
      <!-- 选择日期 -->
      <van-calendar v-model="showCalendar"
                    :min-date="minDate"
                    :type="calendarType"
                    @close="onClose"
                    @confirm="onConfirm" />
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in appointmentData"
               :key="item.appointmentId">
            <item-card class="list-item"
                       :title="item.classroomName"
                       :status="parseStatus(item.status)">
              <div class="content">
                <!-- <div class="content-line">
                  <span class="name">楼栋:</span>
                  <span class="value">{{ item.buildingName }}</span>
                </div> -->
                <div class="content-line">
                  <span class="name">借用时间:</span>
                  <span class="value line">
                    <template v-for="(items,inds) in item.dates">
                      {{ items.borrowStartTime }}~{{ items.borrowEndTime}}
                      {{item.dates.length-1!=inds?',':''}}
                    </template>
                  </span>
                  <!-- <span class="value"> {{ item.borrowStartTime }} - {{ item.borrowEndTime }}</span> -->
                </div>
                <div class="content-line">
                  <span class="name">联系人:</span>
                  <span class="value">{{ item.contactName }}</span>
                </div>
                <div class="content-line">
                  <span class="name">联系电话:</span>
                  <span class="value">{{ item.contactMobileNumber }}</span>
                </div>
                <div class="content-line">
                  <span class="name">值班老师:</span>
                  <span class="value">{{ item.staffNames }}</span>
                </div>
                <div class="content-line">
                  <span class="name">单位:</span>
                  <span class="value">{{ item.organizationName }}</span>
                </div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn"
                              type="info"
                              size="small"
                              round
                              @click="detailsBut(item)">详情</van-button>
                </div>
              </template>
            </item-card>

          </div>
          <template #finished>
            <div v-if="appointmentData.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!appointmentData.length" />
    </div>

  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomAppointmentRecord.less";
import VueQr from "vue-qr";
import { SUCCESS_CODE } from "@/apis/notification";
import formUtils from "@/entry/form/components/forms/utils.js";

export default {
  name: "UserAppointmentList",
  data() {
    return {
      filter: {
        buildingId: {
          id: "",
          label: "楼栋"
        },
        classroomId: {
          id: "",
          label: "课室"
        },
        status: {
          id: "",
          label: "所有状态"
        },
        time: {
          id: "",
          label: "日期"
        },
        title: "所有状态"
      },
      listStatus: {
        loading: true,
        finished: false
      },
      showVanPopupType: false,
      showCalendar: false,
      calendarType: "",
      minDate: "",
      vanPopupTypeColumns: [],
      pager: {
        pageNumber: 1,
        pageSize: 5
      },
      screen: {
        status: "",
        buildingId: "",
        classroomId: "",
        time: ""
      },
      appointmentData: []
    };
  },
  methods: {
    itemClick(id) {
      this.$router.push({
        name: "borrowEdit",
        query: {
          id
        }
      });
    },

    vanPopupCancel() {
      this.showVanPopupType = false;
    },

    showCalendarBtn(type) {
      this.showCalendar = true;
      this.calendarType = type;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.isCalendar = true;
      if (this.calendarType == "range") {
        this.screen.time = [this.$moment(date[0]).format("YYYY-MM-DD"), this.$moment(date[1]).format("YYYY-MM-DD")];
        this.filter.time.label = this.$moment(date[0]).format("YYYY-MM-DD") + "~" + this.$moment(date[1]).format("YYYY-MM-DD");
        this.filter.time.id = "";
      }
      this.getInitialData();
    },
    onClose(date) {
      if (!this.isCalendar) {
        this.filter.time.label = "日期";
        this.screen.time = [];
        this.getInitialData();
      }
      this.isCalendar = false;
    },

    parseStatus(item) {
      if (item == "CREATED") return "新建";
      if (item == "APPROVED") return "通过";
      if (item == "UNDER_REVIEW") return "审核中";
      if (item == "REJECTED") return "已驳回";
      if (item == "EXPIRED") return "已过期";
    },

    onLoad() {
      this.getClassroomAppointments(true);
    },

    getCodeBtn(row) { },

    async init() {
      // console.log(123, $keycloak.tokenParsed.name);
      this.pager = {
        pageNumber: 1,
        pageSize: 5
      }
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.getClassroomAppointments();
      this.getClassroomBuildings();
    },
    // 获取楼栋信息列表
    async getClassroomBuildings() {
      let ret = await this.$api.getClassroomBuildings();
      this.buildList = ret.data;
    },
    // 获取课室预约列表
    async getClassroomAppointments(sign = false) {
      let params = {
        pageNumber: this.pager.pageNumber - 1,
        pageSize: this.pager.pageSize
        // buildingId: this.screen.buildingId,
        // classroomId: this.screen.classroomId,
        // useType: "APPLY_USER"
      };
      if (this.screen.status) params.status = this.screen.status;
      if (this.screen.time && this.screen.time.length > 0) {
        params.startTime = this.screen.time[0] + " 00:00";
        params.endTime = this.screen.time[1] + " 23:59";
      }
      let ret = await this.$api.getClassroomAppointmentList({
        params
      });
      if (ret.code != SUCCESS_CODE) return;
      this.pager.totalRecords = ret.totalRecords;
      if (sign) {
        this.appointmentData = this.appointmentData.concat(ret.data);
      } else {
        this.appointmentData = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    // 获取课室列表
    async getClassrooms() {
      let ret = await this.$api.getClassroomList();
      this.classroomsList = ret.data;
    },
    vanPopupConfirm(val) {
      let name = val.name;
      console.log(1234, val);
      if (name == "全部") {
        if (val.key == "buildingId") name = "楼栋";
        if (val.key == "classroomId") name = "课室";
      }
      this.showVanPopupType = false;
      this.screen[val.key] = val.id;
      this.filter[val.key].label = name;
      this.filter[val.key].id = val.id;
      if (val.key == "buildingId") {
        this.changeBuild();
      }
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.pager.pageNumber = 1;
      this.getClassroomAppointments();
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    changeBuild() {
      this.filter.classroomId = { id: "", label: "课室" };
      this.screen.classroomId = "";
      if (this.screen.buildingId) {
        let ind = this.buildList.findIndex(val => {
          return val.id == this.screen.buildingId;
        });
        this.classroomsList = this.buildList[ind].classrooms;
      } else {
        this.screen.classroomId = "";
        this.classroomsList = [];
      }
    },
    vanPopupClick(type) {
      if (type == "buildingId") {
        this.filter.title = "楼栋";
        this.vanPopupTypeColumns = [{ id: "", name: "全部", key: "buildingId" }].concat(
          this.buildList.map(val => {
            return { id: val.id, name: val.name, key: "buildingId" };
          })
        );
      }
      if (type == "classroomId") {
        this.filter.title = "课室";
        this.vanPopupTypeColumns = [{ id: "", name: "全部", key: "classroomId" }].concat(
          this.classroomsList.map(val => {
            return { id: val.id, name: val.name, key: "classroomId" };
          })
        );
      }
      if (type == "status") {
        this.filter.title = "状态";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "status" },
          // { id: "CREATED", name: "新建", key: "status" },
          { id: "APPROVED", name: "通过", key: "status" },
          { id: "UNDER_REVIEW", name: "审核中", key: "status" },
          { id: "REJECTED", name: "已驳回", key: "status" },
          { id: "EXPIRED", name: "已过期", key: "status" }
        ];
      }
      this.showVanPopupType = true;
    },
    async detailsBut(item) {
      let classroomId = item.id;
      let businessKey = "classroom-apply:" + item.appointmentId;
      let ret = await this.$api.getHistoryTaskList({
        params: {
          processInstanceBusinessKey: businessKey
        }
      });
      if (ret.code != SUCCESS_CODE) {
        return console.error("没有找到实例");
      }
      if (!ret.data.length) return this.$utils.warning("该运行实例已被删除");

      let processInstanceId = ret.data[0].rootProcessInstanceId;
      let state = ret.data[0].state;

      let varibles = await formUtils.getHistoryProcessInstanceVaribles(processInstanceId);
      varibles.query.renderFormKey = varibles.query.renderFormKey.toString();

      let url = this.$utils.getFormUrlByKey("classroomReserveDetail", {
        state,
        renderFormKey: varibles.query.renderFormKey,
        classroomId,
        processInstanceId,
        readOnly: 1
      });
      location.href = url;
    }
  },
  created() {
    this.init();
  },
  filters: {}
};
</script>

<style></style>
